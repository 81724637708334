export const environment = {
  type:"development", // or "production" of "local"
  production: false,
  google_client_id: '355490807214-83nap2fpt6adnt4sivi78vjses1m9d56.apps.googleusercontent.com',
  facebook_id: '1460509394113402',
  microsoft_client_id: '3daa15b4-dff5-4423-9bd9-b4ab0772b63f',
  linked_in_client_id: '77acv9pq06bjk3',
  sentry_dsn: 'https://35b794252dc54b27a3a6e640f457a6a9@o205259.ingest.sentry.io/5274289',
  ga: '',
  dropbox_client_id: 'j0jyr5pgftitzwb',
  recaptcha: { 
        siteKey: '6LdWccUgAAAAAO9JtDSqVpYt8zb1G7wPtNWBxuTu', 
  }
};